/*
 * GENERAL CLASSES
 * @author:Rianne Oosthoek
 *
 * In many cases you'll need the same css multiple times for different elements throughout your website.
 * Place the classes with those css in this file.
 */

/* Default browser style override */
select {-webkit-appearance:none !important; -moz-appearance:none !important; -ms-appearance:none !important;}
*::selection {
    background:$secondary-color;
}

.main{
    min-height:100vh;
    background:$primary-color;
}


.padding-vertical{padding:40px 0;}
.padding-big-vertical{padding:80px 0;}
.padding-big-bottom{padding:120px 0;}
.padding-vertical-only-bottom{padding-bottom:40px;}
.padding-vertical-only-top{padding-top:40px;} 
.padding-big-only-top{padding-top:80px;}  
.margin-only-small{margin: 40px 0;}

/* BANNER */
.banner-wrapper{
    &:before {
        width:100%; height:100%;
        position:absolute; left:0; 
        background:$secondary-color;
        transform:skew(20deg);
        content:"";
    }
    .banner{
        width:100%; height:100%; padding:10px 20px;
        position:relative; z-index:9999;
        color:$white; font-weight:700;
    }
}

/* WINNERS */
.winners-overview{
    overflow:auto;
    .heading{
        min-width:640px; margin:50px 0 25px 0; padding-bottom:25px;
        border-bottom:1px solid $white;
        color:$white;
    }
    .winner{
        min-width:640px; margin:5px 0 0 0;
        color:$white;
    }
}
.fade-right{
    &:after{
        width:20px; height:100%;
        position:absolute; top:0; right:0;
        content:"";
        background:linear-gradient(to right, transparent , $primary-color-darker);
    }
}

/* IMAGE SUBPAGE */
.image-subpage{
    width:100%; max-width:320px; margin:0 auto;
    display:block;
}
.header-logo{ max-height:80px; }

.relative{
    @include position();
}
.title {
    text-align:center; color:$primary-color;
    margin:0px 0px 35px 0px;
    z-index:10; position:relative;
    &.left{ text-align:left; }
    &.right{ text-align:right; }
    &.contact{ padding:0 21%;}
}
.white{
    color:$white;
    &:hover, &:focus{
        color:$white;
    }
}
.circular {
    margin:auto; width:150px;
    border-radius:150px;
    display:block;
    background-color:white;
}
.relative{
    position:relative;
}
.overlay{
    @include position( $position:absolute, $top:0, $zindex:0 );
    width:100%; height:100%;
    background-color:$primary-color;
    opacity:0.7;
    &.header-overlay{
        opacity:0.5;
    }
}
.gray-overlay{
    @include position( $position:absolute, $top:0, $zindex:0 );
    width:100%; height:100%;
    background-color:#b6bbbb;
    opacity:0.5;
}
.background-color{ background-color:$primary-color; }
.no-margin{ margin:0 !important; }
.no-padding{ padding:0 !important; }
.center{ text-align:center!important; }
.right{ text-align:right; }
.hidden{ display:none; }
.invisible{ visibility:hidden; }
.clear{ clear:both; }
.white{ color:$white!important; }
.relative{ position: relative; }

.button{
    margin-top:40px; padding:10px 25px 10px 15px;
    background-color:$white; border:none; border-radius:5px;
    font-weight:700; font-size:1.6rem; color:$primary-color;
    transition:color .7s ease;
    &:hover{ color:$primary-color-light; }
    &:focus{ outline:none; }
    &:after{
        position:absolute;
        font-family:"one-pager"; line-height:14px;
        content:"\68";
    }
    &.back{
        padding:10px 25px;
        &:after{ content:""; }
    }
    &.error{ &:after{ line-height:26px; } }
}
.no-scroll{
    overflow:hidden;
}
.move{
    cursor:move;
}
/* SLICK SLIDER */
.slick-arrow{
    font-size:4rem; color:$black;
    position:absolute;
    top:36%;
    transform:translate3d(0, -45%, 0);
    z-index:1;
    outline:none;
    &.slick-prev-button{
        left:0;
    }
    &.slick-next-button{
        right:0;
    }
}
.slick-slide {
    outline:none;
    height:inherit;
}
/* SHARE */
.share{
    height:205px; width:50px;
    float:right; position:fixed; top:30%; right:0; left:auto; bottom:0; z-index:500;
    background-color:#d8f0fc;
    display:none;
}
.share-text{
    padding:10px 0px 12px 10px;
    color:$white; font-size:1.2rem; text-transform:uppercase;
    cursor:pointer;
    transition:color 250ms ease-in-out;
}
.social-item{
    width:35px; height:35px; margin:13px 0 0px 8px; padding:10px 0px 0px 9px;
    border-radius:50%;
    display:inline-block;
    &.social-footer{
        margin:0px 0 0px 10px; padding:10px 0px 0px 0px;
    }
    &.facebook{
        background-color:#00558e;
    }
    &.twitter{
        background-color:#79d2e2;
    }
    &.linkedin{
        background-color:#00aceb;
    }
    &.googleplus{
        background-color:#79e5f8;
    }
    .social-anchor{
        color:$white;
        transition:color 250ms ease-in-out;
        &:hover{
            color:$white;
        }
    }
}


.menu-icon-ever{
    padding:0 0 50px 30px;
    position:absolute; right:15px; z-index:115;
    .icon{
        position:absolute; left:0px; top:28px;
        &:before{
            width:30px; height:4px;
            position:absolute; top:0px;
            background:$white; border-radius:10px;
            transition: transform 200ms linear, top 200ms linear, background-color 200ms linear;
            content: "";
        }
        &:after{
            width:30px; height:4px;
            position:absolute; top:8px;
            background:$white; border-radius:10px; box-shadow:0 8px 0 0 $white;
            transition: transform 200ms linear, box-shadow 200ms linear, background-color 200ms linear;
            content:"";
        }
    }
    .collapsed{
        &:before{background-color:$white; transition:transform 200ms linear, box-shadow 200ms linear, top 200ms linear; top:8px;}
        &:after{background-color:$white; box-shadow:0 0px 0 0 $white; transition: transform 200ms linear, box-shadow 200ms linear;}
    }
    .crossed{
        &:before{top:8px; background-color:$white; transform:rotate(45deg);}
        &:after{box-shadow:none; background-color:$white; transform:rotate(-45deg);}
    }
}

/* COOKIE BAR */
.cookie-bar{
    width:100%;
    position:fixed; bottom:0; left:0;  z-index:1000;
    background:rgba(0, 0, 0, 0.8);
    color:$white;
    &.cookie-top{
        margin-top:-100px;
        position:relative;
        opacity:1;
        transition:top 1s;
    }
    .cookie-text{
        margin:10px 0 0 0;
        font-size:1.2rem; 
        display:inline-block;    
    }
    .button{
        padding:15px; margin:10px 0px 10px 0px;
        border:1px solid; color:$white;
        display:inline-block;
        &:hover{
            color:$black;
        }
    }
}

/* TERMS AND CONDITIONS */
.conditions{
    position:absolute; right:0; bottom:-70px;
    .condition-list{
        list-style:none;
        .condition-item{
            padding-left:30px;
            display:inline-block;
            color:$white; font-size:1.1rem;
            &:after{
                position:relative; top:0; right:-15px;
                content:"I";
            }
            &:last-child{ &:after{ content:""; } }
            .condition-anchor{ color:$white; }
        }
    }
}

/* FORM INPUT */
.fieldset{
    margin-bottom:15px;
    position:relative;
    &:last-child{ margin-bottom:40px; }
    &.readonly{ opacity:.5; }
    &.half-left{ width:49.5%; display:inline-block; .input{ margin:0; } }
    &.half-right{ width:49.5%; display:inline-block; float:right; .input{ margin:0; } }
    .label{
        position:absolute; top:15px; left:8px;
        color:$white; font-size:1.4rem;
        transform:translate3d(0, 0, 0);
        transition:all 0.2s  ease-out;
    }
    .input{
        display:block;
        width:100%; height:3em; padding:15px 8px; 
        border:0; border-radius:0; outline:0;
        font-size:1.8rem; font-weight:400;
        line-height:1; color:$medium-gray;
        background-color:transparent; appearance:none;
        border-bottom:1px solid $white;
        transition:all 0.2s  ease-out;
        &:focus{ 
            outline:0;
        }
        &.valid,
        &:focus{
            padding:22px 8px 10px;
        }
        &.valid + .label,
        &:focus + .label{ 
            font-weight:700; font-size:12px; color:$primary-color-light;
            transform:translate3d(0, -10px, 0);
        }
        &.explanation{ height:13em; }
        &.motivation-signup{ height:36.2em; }
        &.comments{ height:13em; }
        &.select{
            &.valid,
            &:focus{
                padding:16px 8px;
            }
        }
    }
}

.g-recaptcha{
    iframe{ margin-left:-38px; transform:scale(.75); }
}

// ANIMATION
.animated {
  -webkit-animation-duration:1s;
  animation-duration:1s;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity:0;
    -webkit-transform:translate3d(0, 100%, 0);
    transform:translate3d(0, 100%, 0);
}

to {
    opacity:1;
    -webkit-transform:none;
    transform:none;
}
}
@-moz-keyframes fadeInUp {
 from {
   opacity:0;
   -moz-transform:translate3d(0, 100%, 0);
   transform:translate3d(0, 100%, 0);
}

to {
    opacity:1;
    -moz-transform:none;
    transform:none;
}
}

@-o-keyframes fadeInUp {
 from {
    opacity:0;
    -o-transform:translate3d(0, 100%, 0);
    transform:translate3d(0, 100%, 0);
}

to {
    opacity:1;
    -o-transform:none;
    transform:none;
}
}

@-ms-keyframes fadeInUp {
   from {
    opacity:0;
    -ms-transform:translate3d(0, 100%, 0);
    transform:translate3d(0, 100%, 0);
}  

to {
    opacity:1;
    -ms-transform:none;
    transform:none;
}  
}

@keyframes fadeInUp {
  from {
    opacity:0;
    -webkit-transform:translate3d(0, 100%, 0);
    transform:translate3d(0, 100%, 0);
}

to {
    opacity:1;
    -webkit-transform:none;
    transform:none;
}
}
.fadeInUp {
  -webkit-animation-name:fadeInUp;
  animation-name:fadeInUp;
  visibility:visible !important;
}


@include breakpoint(medium) { /* min-width:480 */
    /* COOKIE BAR */
    .cookie-bar{
        .button{
            float:right;
        }
    }
    .social-item{
        &.social-footer{
            margin:35px 0 0px 15px;
        }
    }
}

@include breakpoint(large) { /* min-width:768 */
    .share{display:block;}
    .padding-vertical{padding:100px 0;}
    .padding-big-vertical{padding:200px 0;}
    .padding-big-bottom{padding-bottom:275px;}
    .padding-vertical-only-bottom{padding-bottom:100px;}
    /* SLICK SLIDER */
    .slick-arrow{top:45%;}

    /* COOKIE BAR */
    .cookie-bar{
        .cookie-text{
            margin-top:13px;
        }
        .button{
            float:right;
        }
    }
    .no-touch{
        &.fixed-bg{background-attachment:fixed;} 
    }

}

@include breakpoint(xlarge) { /* min-width:768 */

    .fade-right{
        &:after{
            display:none;
        }
    }

.margin-only-small{margin: 0;}
}